import * as React from 'react'
import { Dialog } from '@material-ui/core'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  miniPicture: {
    height: '100px',
    width: '100px',
    objectFit: 'cover',
    cursor: 'pointer'
  }
})
interface Props {
  src: string
}
export const ClickablePicture = ({ src }: Props) => {
  const [open, setOpen] = useState(false)
  const styles = useStyles()
  return (
    <>
      <img
        src={src}
        onClick={() => {
          setOpen(true)
        }}
        className={styles.miniPicture}
        alt=""
      />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <img src={src} alt="" />
      </Dialog>
    </>
  )
}
