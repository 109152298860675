import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { OneNews } from './OneNews'

const useStyles = makeStyles({
  news: {
    marginLeft: '20px'
  },
  paggination: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    bottom: '150px',
    left: '50%',
    transform: 'translateX(-50%)',
    ['@media(max-width: 1010px)']: {
      bottom: '350px'
    }
  },
  pageNumber: {
    padding: '0 20px'
  },
  arrow: {
    cursor: 'pointer'
  }
})

interface News {
  title: string
  date: string
  content: string
  pictures: string
}

interface Props {
  newsList: Array<News>
  isEn?: boolean
}
export const NewsComponent = ({ newsList }: Props) => {
  const [page, setPage] = useState(0)
  const [elements, setElements] = useState<Array<News>>([])
  const styles = useStyles()
  const reversedNews = newsList.reverse()

  useEffect(() => {
    const newElements: Array<News> = []
    for (let i = page; i < elementsPerPage && i < reversedNews.length; i++) {
      newElements.push(reversedNews[i])
    }
    setElements(newElements)
  }, [page])

  const elementsPerPage = 5
  const nextPage = () => {
    setPage(prevState => {
      if (elements.length < 5) return prevState
      return prevState + 1
    })
  }
  const previousPage = () => {
    setPage(prevState => {
      if (prevState == 0) return prevState
      return prevState - 1
    })
  }

  return (
    <div style={{ height: 'auto' }}>
      <div className={styles.news}>
        {elements.map(news => (
          <OneNews news={news} />
        ))}
      </div>
      <div className={styles.paggination}>
        <div onClick={previousPage} className={styles.arrow}>{`<`}</div>
        <div className={styles.pageNumber}> {page + 1} </div>
        <div onClick={nextPage} className={styles.arrow}>{`>`}</div>
      </div>
    </div>
  )
}
