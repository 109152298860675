import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import BaseSlider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const useStyles = makeStyles({
  wrapper: {
    height: '300px',
    width: '1010px',
    margin: 'auto',
    paddingTop: '20px',
    ['@media (max-width: 1010px)']: {
      height: '200px',
      width: '100%',
      margin: '0'
    }
  },
  sliderPhoto: {
    height: '300px',
    display: 'inline-block',
    objectFit: 'cover',
    margin: 'auto',
    ['@media (max-width: 1010px)']: {
      height: '200px',
      width: '100%',
      margin: '0'
    }
  }
})
interface Picture {
  picture: string
}
interface Props {
  pictures: Array<Picture>
}
const Slider = ({ pictures }: Props) => {
  const styles = useStyles()
  const photos = pictures.map(({ picture }: Picture) => picture)
  const settings: Settings = {
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    pauseOnDotsHover: true
  }
  return (
    <div className={styles.wrapper}>
      <BaseSlider {...settings}>
        {photos.map(photo => (
          <img src={photo} className={styles.sliderPhoto} alt="" />
        ))}
      </BaseSlider>
    </div>
  )
}

export default Slider
