import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import { NewsModal } from './NewsModal'
import { format } from 'date-fns'

const useStyles = makeStyles(theme => ({
  news: {
    cursor: 'pointer',
    paddingBottom: '20px',
    display: 'inline-block',
    '&:hover': {
      color: theme.palette.primary.light
    }
  },
  title: {
    fontSize: '24px'
  },
  date: {}
}))
interface News {
  title: string
  date: string
  content: string
  pictures: string
}

interface Props {
  news: News
  isEn?: boolean
}
export const OneNews = ({ news, isEn }: Props) => {
  const styles = useStyles()
  const [open, setOpen] = useState(false)
  const formatedDate = format(new Date(news.date), 'yyyy-MM-dd')
  const date = `${isEn ? 'Date' : 'Data'}: ${formatedDate}`
  return (
    <div>
      <div
        onClick={() => {
          setOpen(true)
        }}
        className={styles.news}
      >
        <div className={styles.title}>{news.title}</div>
        <div className={styles.date}>{date}</div>
      </div>
      <NewsModal
        news={news}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      />
    </div>
  )
}
