import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { ClickablePicture } from './ClickablePicture'
import { Dialog, makeStyles } from '@material-ui/core'
import { format } from 'date-fns'

const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: '#FBFBF8',
    width: '900px',
    minHeight: '50vh',
    margin: '10px auto',
    position: 'relative',
    padding: '20px',

    ['@media (max-width: 1010px)']: {
      width: 'auto'
    }
  },
  picture: {
    width: '300px',
    height: '300px',
    objectFit: 'cover',
    ['@media (max-width: 1010px)']: {
      display: 'flex',
      justifyContent: 'center',
      margin: '20px auto'
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 'bold'
  },
  date: {
    position: 'absolute',
    top: '-5px',
    right: '10px',
    fontWeight: 'bold'
  },
  content: {
    padding: '0 20px',
    fontSize: '18px',
    textAlign: 'justify',
    textJustify: 'inter-word',
    ['@media (max-width: 1010px)']: {
      padding: '20px'
    }
  },
  flexContainer: {
    padding: '20px',
    paddingBottom: '120px',
    display: 'flex',
    ['@media (max-width: 1010px)']: {
      display: 'block'
    }
  },
  flexImages: {
    display: 'flex',
    bottom: '20px',
    position: 'absolute',
    flexWrap: 'wrap'
  }
}))
interface News {
  title: string
  date: string
  content: string
  pictures: string
}

interface Props {
  onClose: () => void
  open: boolean
  news: News
}
export const NewsModal = ({ open, news, onClose }: Props) => {
  const styles = useStyles()
  const { title, date, content } = news
  const formatedDate = format(new Date(date), 'yyyy-MM-dd')
  const pictures = news.pictures?.split(' ')
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} scroll="body">
      <div className={styles.modal}>
        <div className={styles.title}>{title}</div>
        <div className={styles.date}>{formatedDate}</div>
        <div className={styles.flexContainer}>
          {pictures && (
            <img src={pictures[0]} className={styles.picture} alt={title} />
          )}
          <div className={styles.content}>{ReactHtmlParser(content)}</div>
        </div>
        {pictures && (
          <div className={styles.flexImages}>
            {pictures.map(picture => (
              <ClickablePicture src={picture} />
            ))}
          </div>
        )}
      </div>
    </Dialog>
  )
}
